<template>
	<el-dialog :title="$dict('添加预约')" top="1vh" width="1400px" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form class="form" label-width="140px">

	 	 	<div class="big_tit" style="margin: 0">{{ $dict('booking确认') }}</div>
	  		<div class="tab1_inner">
				<el-form-item class="el_form_item" label="booking">
					<el-input class="el_inner_width" v-model="booking_num_in_page" clearable>
						<el-button slot="append" @click="business_container_ser">{{ $dict('查找') }}</el-button>
					</el-input>
				</el-form-item>
	  		</div>
			<div class="tab_height" style="margin-top:15px;">
				<el-table :data="business_container_list" 
					highlight-current-row :border="true" size="small" height="100%"
					@current-change="business_container_choosed"
				>
					<el-table-column :label="$dict('箱型')" prop="container_type_name" width="100"></el-table-column>
					<el-table-column :label="$dict('堆场')">
						<template slot-scope="scope">
							<div>{{scope.row.ctyard_info.name}}</div>
						</template>
					</el-table-column>
					<el-table-column :label="$dict('工厂(装箱点)')" prop="factory_name"></el-table-column>
					<el-table-column :label="$dict('堆场(提箱点)')" prop="ctyard_name"></el-table-column>
					<el-table-column :label="$dict('货代')" prop="forwarder_name"></el-table-column>
					<el-table-column :label="$dict('最后修改时间')" prop="update_time_text" width="145"></el-table-column>
					<el-table-column :label="$dict('备注')" prop="mark" width="140"></el-table-column>
				</el-table>
	  		</div>

			<div class="big_tit">{{ $dict('可预约时段') }}</div>
	  		<div class="tab_height">
				<el-table :data="ctyard_bookable_container_list" 
				highlight-current-row :border="true" size="small" height="100%" 
				@current-change="ctyard_bookable_container_choosed"
			>
					<el-table-column :label="$dict('日期/时段')">
						<template slot-scope="scope">
							<div>{{scope.row.ctyard_bookable_date_text}} / {{scope.row.ctyard_bookable_start_time_text}} ~ {{scope.row.ctyard_bookable_end_time_text}}</div>
						</template>
					</el-table-column>
					<el-table-column :label="$dict('可预约量')" prop="surplus_total"></el-table-column>
					<el-table-column :label="$dict('总数量')" prop="quantity_total"></el-table-column>
					<el-table-column :label="$dict('已预约未到达')" prop="quantity_reserved"></el-table-column>
					<el-table-column :label="$dict('已预约已到达')" prop="quantity_arrived"></el-table-column>
					<el-table-column :label="$dict('已完成数量')" prop="quantity_completed"></el-table-column>
					<el-table-column :label="$dict('备注')" prop="mark"></el-table-column>
				</el-table>
	  		</div>

			<div style="display: flex;justify-content: space-between;">
				<div style="width: 35%;">
					<div class="big_tit">
						<div>{{ $dict('费用列表') }}</div>
						<el-button @click="fee_detail_add" type="primary" size="mini" style="position: absolute; right: 10px; top: 8px">{{ $dict('增加一行') }}</el-button>
					</div>
					<div class="tab_height">
						<el-table :data="fee_detail" :border="true" :stripe="true" size="small" height="100%">
							<el-table-column :label="$dict('名称')" >
								<template slot-scope="scope">
									<el-input v-model="scope.row.name" disabled></el-input>
								</template>
							</el-table-column>
							<el-table-column :label="$dict('金额')" width="120">
								<template slot-scope="scope">
									<el-input v-model="scope.row.much" disabled></el-input>
								</template>
							</el-table-column>

							<!-- 操作行 -->
							<el-table-column :label="$dict('操作')" width="90">
								<template slot-scope="scope">
									<el-button @click="fee_detail_del(scope.$index)" size="mini" type="text" style="color: red">{{ $dict('删除') }}</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div style="width: 29.8%;">
					<div class="big_tit">{{ $dict('选择运力') }}</div>
					<el-form label-width="80px" style="margin-top: 22px;">
						<el-form-item :label="$dict('车队')" >
							<el-input class="el_inner_width" v-model="truck_carrier_info.name" disabled></el-input>
						</el-form-item>
						<el-form-item :label="$dict('司机')" >
							<el-input class="el_inner_width" v-model="truck_driver_choose.name" disabled>
								<el-button slot="append" @click="truck_driver_choose_open">{{ $dict('选择') }}</el-button>
							</el-input>
						</el-form-item>
						<el-form-item :label="$dict('货车')" >
							<el-input class="el_inner_width" v-model="truck_van_choose.truck_van_plate_num" disabled>
								<el-button slot="append" @click="truck_van_choose_open">{{ $dict('选择') }}</el-button>
							</el-input>
						</el-form-item>
						<el-form-item :label="$dict('挂车')" >
							<el-input class="el_inner_width" v-model="truck_trailer_choose.truck_trailer_plate_num" disabled>
								<el-button slot="append" @click="truck_trailer_choose_open">{{ $dict('选择') }}</el-button>
							</el-input>
						</el-form-item>
					</el-form>
				</div>
				<div style="width: 34.8%;">
					<div class="big_tit">{{ $dict('开票相关') }}</div>
					<el-form label-width="140px" style="margin-top: 22px;">
						<el-form-item :label="$dict('开票对象')" >
							<el-input class="el_inner_width" v-model="invoice_for_choose.name" disabled>
								<el-button slot="append" @click="invoice_for_choose_open">{{ $dict('选择') }}</el-button>
							</el-input>
						</el-form-item>
						<el-form-item :label="$dict('开票点数')" >
							<el-input class="el_inner_width" v-model="tax_rate" disabled>
								<template slot="append">%</template>
							</el-input>
						</el-form-item>
						<el-form-item :label="$dict('计税方式')">
							<el-select class="el_inner_width" v-model="tax_type" disabled>
								<el-option label="除法" value="1"></el-option>
								<el-option label="乘法" value="2"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item :label="$dict('税金总额')" >
							<el-input class="el_inner_width" v-model="tax_much" disabled></el-input>
						</el-form-item>
					</el-form>
				</div>
				
			</div>
			<div style="text-align:center;">
				<el-button @click="sub" type="primary">{{ $dict('提交') }}</el-button>
			</div>
		</el-form>

		<!-- 司机选择弹出层 -->
		<truckDriverChoose 
			:is_show="truck_driver_choose.is_show" 
			@choosed="truck_driver_choosed" 
		></truckDriverChoose>

		<!-- 货车选择弹出层 -->
		<truckVanChoose 
			:is_show="truck_van_choose.is_show" 
			@choosed="truck_van_choosed" 
		></truckVanChoose>

		<!-- 挂车选择弹出层 -->
		<truckTrailerChoose 
			:is_show="truck_trailer_choose.is_show" 
			@choosed="truck_trailer_choosed" 
		></truckTrailerChoose>

		<!-- 开票对象选择弹出层 -->
		<invoiceForChoose 
			:is_show="invoice_for_choose.is_show" 
			:factory_name="business_container.factory_info.name" 
			:forwarder_name="business_container.forwarder_info.name" 
			:truck_carrier_name="truck_carrier_info.name" 
			@choosed="invoice_for_choosed" 
		></invoiceForChoose>

  	</el-dialog>
</template>
<script>
	import {mapState} from 'vuex'
	import truckDriverChoose from '../../truck_carrier/truck_carrier_bind_truck_driver/choose.vue'
	import truckVanChoose from '../../truck_carrier/truck_carrier_bind_truck_van/choose.vue'
	import truckTrailerChoose from '../../truck_carrier/truck_carrier_bind_truck_trailer/choose.vue'
	import invoiceForChoose from './invoice_for_choose_view.vue'
	export default {
		components:{
			truckDriverChoose,
			truckVanChoose,
			truckTrailerChoose,
			invoiceForChoose,
		},
		props: {
			is_show: Number,
			booking_num:'',
		},
		data() {
			return {

				//是否显示
				is_show_in_page: false,

				//基础数据
				business_container_list:[],
				ctyard_bookable_container_list:[],

				//选定数据
				business_container:{
					factory_info:{},
					forwarder_info:{},
				},
				ctyard_bookable_container:{},

				//booking号
				booking_num_in_page:'',

				//司机选择
				truck_driver_choose:{
					is_show:0,
					driver_user_id:'',
					name:'',
				},

				//货车选择
				truck_van_choose:{
					is_show:0,
					truck_van_plate_num:'',
				},

				//挂车选择
				truck_trailer_choose:{
					is_show:0,
					truck_trailer_plate_num:'',
				},
				
				//开票对象
				invoice_for_choose:{
					is_show:0,
					invoice_for:'',
					name:'',
				},

				//费用详情
				fee_detail:[],
				tax_disabled:true,
				tax_rate:'',
				tax_type:'',
				tax_much:'',
			};
		},
		watch: {
			is_show(new_data) {
				if (new_data) {

					//打开弹出层
					this.is_show_in_page = true;

					//初始化
					this.init();

				} else this.is_show_in_page = false;
			},
		},
		computed:{
			...mapState(['basic_data','truck_carrier_info'])
		},
 	 	methods: {

			//提交
			sub(){

				if(!this.booking_num_in_page.trim()){
					this.$my.other.msg({
						type: "warning",
						str: "缺少booking编号",
					});
					return;
				}

				if(!this.business_container.id){
					this.$my.other.msg({
						type: "warning",
						str: "缺少集装箱业务",
					});
					return;
				}

				if(!this.ctyard_bookable_container.id){
					this.$my.other.msg({
						type: "warning",
						str: "缺少可约时段",
					});
					return;
				}

				if(!this.truck_driver_choose.driver_user_id){
					this.$my.other.msg({
						type: "warning",
						str: "缺少司机",
					});
					return;
				}

				if(!this.$my.check.is_plate_num(this.truck_van_choose.truck_van_plate_num)){
					this.$my.other.msg({
						type: "warning",
						str: "缺少货车车牌号",
					});
					return;
				}

				this.invoice_for_choose.invoice_for=parseInt(this.invoice_for_choose.invoice_for);
				if([1,2,3].indexOf(this.invoice_for_choose.invoice_for)==-1){
					this.$my.other.msg({
						type: "warning",
						str: "请选择开票对象",
					});
					return;
				}

				if(!this.$my.check.is_num(this.tax_rate)){
					this.$my.other.msg({
						type: "warning",
						str: "缺少开票点数",
					});
					return;
				}
				if(['1','2'].indexOf(this.tax_type) == -1){
					this.$my.other.msg({
						type: "warning",
						str: "缺少计税方式",
					});
					return;
				}
				if(!this.$my.check.is_num(this.tax_much)){
					this.$my.other.msg({
						type: "warning",
						str: "缺少税金总额",
					});
					return;
				}

				//费用详情
				let fee_detail_list = [];
				let fee_detail_check = [];
				for(let index in this.fee_detail){

        			index = parseInt(index);

					//取出item
					let item=this.fee_detail[index];

					//重要数据校验
					if(!item.name.trim()){
						this.$my.other.msg({
							type: "warning",
							str: `第 ${index + 1} 条缺少费项名称`,
						});
						return;
					}
					if(!(this.$my.check.is_num(item.much) && item.much>0)){
						this.$my.other.msg({
							type: "warning",
							str: `第 ${index + 1} 条缺少金额`,
						});
						return;
					}

					//鉴重
					let check_index = fee_detail_check.indexOf(item.name);
					if (check_index != -1) {
						this.$my.other.msg({
							type: "warning",
							str: `第 ${index + 1} 条的费项名称与第 ${check_index + 1} 条重复`,
						});
						return;
					}

					//置入鉴重数组
					fee_detail_check.push(item.name);

					//置入待添加数据
					fee_detail_list.push({
						name:item.name,
						much:item.much,
					});
				}

				//询问
				this.$my.other.confirm({
					content:"点击确定添加预约信息",
					confirm:()=>{
						this.$my.net.req({
							loading:true,
							data:{
								mod:"ctyard",
								ctr:"ctyard_reserved_add_by_truck_carrier",
								business_container_id:this.business_container.business_container_id,
								ctyard_bookable_container_id:this.ctyard_bookable_container.id,
								ctyard_id:this.business_container.ctyard_id,
								booking_num:this.business_container.booking_num_in_page,
								container_type_name:this.business_container.container_type_name,
								truck_carrier_id:this.truck_carrier_info.truck_carrier_id,
								driver_user_id:this.truck_driver_choose.driver_user_id,
								truck_van_plate_num:this.truck_van_choose.truck_van_plate_num,
								truck_trailer_plate_num:this.truck_trailer_choose.truck_trailer_plate_num,
								invoice_for:this.invoice_for_choose.invoice_for,
								fee_detail:fee_detail_list,
								tax_rate:this.tax_rate,
								tax_type:this.tax_type,
								tax_much:this.tax_much,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:"保存成功"
								});
								this.is_show_in_page=false;
								this.$emit('added')
							},
						});
					}
				});
			},

			//选择司机
			truck_driver_choose_open(){//打开
				this.truck_driver_choose.is_show++
			},
			truck_driver_choosed(rel){//关闭
				this.truck_driver_choose.is_show=0
				this.truck_driver_choose.driver_user_id=rel.driver_user_id
				this.truck_driver_choose.name=rel.driver_user_info.name
			},

			//选择货车
			truck_van_choose_open(){//打开
				this.truck_van_choose.is_show++
			},
			truck_van_choosed(rel){//关闭
				this.truck_van_choose.is_show=0
				this.truck_van_choose.truck_van_plate_num=rel.truck_van_plate_num
			},

			//选择挂车
			truck_trailer_choose_open(){//打开
				this.truck_trailer_choose.is_show++
			},
			truck_trailer_choosed(rel){//关闭
				this.truck_trailer_choose.is_show=0
				this.truck_trailer_choose.truck_trailer_plate_num=rel.truck_trailer_plate_num
			},

			//开票对象选择
			invoice_for_choose_open(){//打开(){//打开
				this.invoice_for_choose.is_show++
			},
			invoice_for_choosed(rel){//关闭
				this.invoice_for_choose.is_show=0;
				this.invoice_for_choose.invoice_for=rel.invoice_for

				//打开锁
				this.tax_disabled=false;

				//取出堆场的默认开票点数
				let tax_rate=this.business_container.ctyard_info.tax_rate
				let tax_type=this.business_container.ctyard_info.tax_type

				//开票对象
				if(rel.invoice_for==1){//工厂
					
					if(this.business_container.factory_id == 0){
						this.$my.other.msg({
							type:'warning',
							str:"此业务没有声明工厂信息,不能设定工厂作为开票对象"
						});
						return;
					}
					this.invoice_for_choose.name=`${this.business_container.factory_name}(工厂)`

					//尝试读取堆场与工厂约定的开票点数
					this.$my.net.req({
						data:{
							mod:"ctyard",
							ctr:"ctyard_bind_factory_list_by_truck_carrier",
							ctyard_id:this.business_container.ctyard_id,
							factory_id:this.business_container.factory_id,
						},
						callback:(data)=>{
							if(data.list.length == 0){
								this.tax_rate=tax_rate
								this.tax_type=tax_type
								this.tax_calc()
								return;
							}
							this.tax_rate=data.list[0].tax_rate
							this.tax_type=data.list[0].tax_type
							this.tax_calc()
						}
					});

				}else if(rel.invoice_for==2){//货代

					if(this.business_container.forwarder_id == 0){
						this.$my.other.msg({
							type:'warning',
							str:"此业务没有声明货代信息,不能设定货代作为开票对象"
						});
						return;
					}
					this.invoice_for_choose.name=`${this.business_container.forwarder_name}(货代)`

					//尝试读取堆场与货代约定的开票点数
					this.$my.net.req({
						data:{
							mod:"ctyard",
							ctr:"ctyard_bind_forwarder_list_by_truck_carrier",
							ctyard_id:this.business_container.ctyard_id,
							forwarder_id:this.business_container.forwarder_id,
						},
						callback:(data)=>{
							if(data.list.length == 0){
								this.tax_rate=tax_rate
								this.tax_type=tax_type
								this.tax_calc()
								return;
							}
							this.tax_rate=data.list[0].tax_rate
							this.tax_type=data.list[0].tax_type
							this.tax_calc()
						}
					});

				}else if(rel.invoice_for==3){//车队

					this.invoice_for_choose.name=`${this.truck_carrier_info.name}(车队)`

					//尝试读取堆场与货代约定的开票点数
					this.$my.net.req({
						data:{
							mod:"ctyard",
							ctr:"ctyard_bind_truck_carrier_list_by_truck_carrier",
							ctyard_id:this.business_container.ctyard_id,
						},
						callback:(data)=>{
							if(data.list.length == 0){
								this.tax_rate=tax_rate
								this.tax_type=tax_type
								this.tax_calc()
								return;
							}
							this.tax_rate=data.list[0].tax_rate
							this.tax_type=data.list[0].tax_type
							this.tax_calc()
						}
					});
				}
			},

			//税金初始化
			tax_init(){
				this.invoice_for_choose.invoice_for=''
				this.invoice_for_choose.name=''
				this.tax_disabled=true;
				this.tax_rate=''
				this.tax_type=''
				this.tax_much=''
			},

			//税金总额计算
			tax_calc(){

				this.tax_much=''
				if(!this.tax_rate)return
				if(!this.tax_type)return

				let fee_total=0;
				for(var item of this.fee_detail){
					fee_total+=parseFloat(item.much)
				}
				if(fee_total==0){
					this.tax_much=0
					return;
				}

				this.$my.net.req({
					data:{
						mod:"ctyard",
						ctr:"ctyard_reserved_tax_calc",
						tax_rate:this.tax_rate,
						tax_type:this.tax_type,
						fee_total:fee_total,
					},
					callback:(data)=>{
						this.tax_much=data.tax_much
					}
				});
			},

			//费用详情添加/删除
			fee_detail_add(index = -1, data) {
				if (index == -1) {
					index = this.fee_detail.length;
				}
				this.fee_detail.splice(index, 0, {
					name: '',
					much: '',
				});
			},
			fee_detail_del(detail_index) {
				this.fee_detail.splice(detail_index, 1);
				this.tax_calc()
			},

			//搜索booking下的可预约箱型
			business_container_ser(){

				if(!(this.booking_num_in_page && this.booking_num_in_page.trim())){
					this.$my.other.msg({
						type: "warning",
						str: "缺少booking号",
					});
					return;
				}

				//读取堆场可预约booking和对应箱型
				this.$my.net.req({
					data:{
						mod:"business",
						ctr:"business_container_list_by_truck_carrier",
						booking_num:this.booking_num_in_page,
						is_get_ctyard_info:1,
						is_get_factory_info:1,
						is_get_forwarder_info:1,
						status:1,
					},
					callback:(data)=>{

						//预处理
						for(let item of data.list){

							//取出各种info
							item.forwarder_info=item.forwarder_info[0]?item.forwarder_info[0]:{};
							item.ctyard_info=item.ctyard_info[0]?item.ctyard_info[0]:{};
							item.factory_info=item.factory_info[0]?item.factory_info[0]:{};

							//取出各种name
							item.forwarder_name=item.forwarder_info.name
							item.ctyard_name=item.ctyard_info.name
							item.factory_name=item.factory_info.name

							//创建/修改时间
							item.create_time_text=this.$my.other.totime(item.create_time);
							item.update_time_text=this.$my.other.totime(item.update_time);
						}

						//置入数据
						this.business_container_list=data.list
					}
				});
			},
			business_container_choosed(item){//booking下的可预约箱型选定
				
				if(!item)return;
				this.business_container=item

				//清空可预约时段
				this.ctyard_bookable_container=[];

				//开票对象
				if(item.belong_type!=4){
					this.invoice_for_choosed({
						invoice_for:item.belong_type
					})
				}

				//清空费用列表
				this.fee_detail=[];
				this.tax_rate=''
				this.tax_type=''
				this.tax_much=''

				//读取可预约时段
				this.ctyard_bookable_container_ser({
					ctyard_id:item.ctyard_id,
					container_type_name:item.container_type_name,
				})
			},

			//读取可预约时段
			ctyard_bookable_container_ser(para){

				//调接口
				this.$my.net.req({
					data:{
						mod:"ctyard",
						ctr:"ctyard_bookable_container_list_by_truck_carrier",
						ctyard_bookable_date_start:parseInt((new Date()).setHours(0,0,0,0)/1000),
						...para,
						status:1,
					},
					callback:(data)=>{

						//预处理
						for(let item of data.list){

							//创建时间
							item.surplus_total=item.quantity_total - item.quantity_reserved - item.quantity_arrived - item.quantity_completed
							
							//日期
							item.ctyard_bookable_date_text=this.$my.other.todate(item.ctyard_bookable_date);

							//预约时段起点
							let start_time_hour=`${parseInt(item.ctyard_bookable_start_time / 3600)}`
							let start_time_min=`${parseInt(item.ctyard_bookable_start_time % 60)}`
							if(start_time_hour<10)start_time_hour=`0${start_time_hour}`
							if(start_time_min<10)start_time_min=`0${start_time_min}`
							item.ctyard_bookable_start_time_text=`${start_time_hour}:${start_time_min}`

							//预约时段止点
							let end_time_hour=`${parseInt(item.ctyard_bookable_end_time / 3600)}`
							let end_time_min=`${parseInt(item.ctyard_bookable_end_time % 60)}`
							if(end_time_hour<10)end_time_hour=`0${end_time_hour}`
							if(end_time_min<10)end_time_min=`0${end_time_min}`
							item.ctyard_bookable_end_time_text=`${end_time_hour}:${end_time_min}`

							//费用详情
							item.fee_detail=JSON.parse(item.fee_detail);
						}

						//置入数据
						this.ctyard_bookable_container_list=data.list
					}
				});
			},
			ctyard_bookable_container_choosed(item){
				if(!item)return;
				this.ctyard_bookable_container=item;
				this.fee_detail=item.fee_detail
				this.tax_calc()
			},

			//初始化
			init() {

				//基础数据
				this.business_container_list=[]
				this.ctyard_bookable_container_list=[]

				//选定数据
				this.business_container={
					factory_info:{},
					forwarder_info:{},
				}
				this.ctyard_bookable_container={}

				//booking号
				this.booking_num_in_page=''

				//司机选择
				this.truck_driver_choose={
					is_show:0,
					driver_user_id:'',
					name:'',
				}

				//货车选择
				this.truck_van_choose={
					is_show:0,
					truck_van_plate_num:'',
				}

				//挂车选择
				this.truck_trailer_choose={
					is_show:0,
					truck_trailer_plate_num:'',
				}

				//开票对象
				this.invoice_for_choose={
					is_show:0,
					invoice_for:'',
					name:'',
				},

				//费用详情
				this.fee_detail=[];
				this.tax_disabled=true;
				this.tax_rate='';
				this.tax_type='';
				this.tax_much='';

				//如果已置入booking则自动查找
				if(this.booking_num&&this.booking_num.trim()){
					this.booking_num_in_page=this.booking_num.trim()
					this.business_container_ser();
				}
			},
  		},
	};
</script>
<style lang="scss" scoped>
	.el_form_item {
		margin: 0;
	}
	.big_tit {
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
		position: relative;
	}
	.tab1_inner {
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width {
		width: 94%;
	}
	.elInnerDiv {
		cursor: pointer;
	}

	.bottomBtns {
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form {
		height: calc(100% - 100px);
		overflow-y: auto;
		margin-top: -20px;
	}

	.tab_height {
		height: 248px;
	}

	.page {
		position: relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}
</style>