 <template>
	<el-dialog :title="$dict('开票对象')" top="1vh" width="600px" append-to-body :visible.sync="is_show_in_page">
		<el-form label-width='140px'>
			<el-form-item :label="$dict('工厂')">
				<el-input v-model="factory_name" disabled>
					<el-button slot="append" @click="choosed(1)">{{ $dict('选择') }}</el-button>
				</el-input>
			</el-form-item>
			<el-form-item :label="$dict('货代')">
				<el-input v-model="forwarder_name" disabled>
					<el-button slot="append" @click="choosed(2)">{{ $dict('选择') }}</el-button>
				</el-input>
			</el-form-item>
			<el-form-item :label="$dict('车队')">
				<el-input v-model="truck_carrier_name" disabled>
					<el-button slot="append" @click="choosed(3)">{{ $dict('选择') }}</el-button>
				</el-input>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
			factory_name:String,
			forwarder_name:String,
			truck_carrier_name:String,
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//初始化
			choosed(invoice_for){

				let invoice_for_text=''
				switch(invoice_for){
					case 1:
						invoice_for_text='工厂';
						if(!this.factory_name){
							this.$my.other.msg({
								type:'warning',
								str:"此业务没有声明工厂信息,不能设定工厂作为开票对象"
							});
							return
						}
						break;
					
					case 2:
						invoice_for_text='货代';
						if(!this.forwarder_name){
							this.$my.other.msg({
								type:'warning',
								str:"此业务没有声明货代信息,不能设定货代作为开票对象"
							});
							return
						}
						break;

					case 3:
						invoice_for_text='车队';
						if(!this.truck_carrier_name){
							this.$my.other.msg({
								type:'warning',
								str:"未选择车队,不能设定车队作为开票对象"
							});
							return
						}
						break;
				}

				//通知
				this.$emit("choosed",{
					invoice_for:invoice_for,
					name:invoice_for_text,
				});
			},
		}
	}
</script>